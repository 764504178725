import glamour from "../images/glamourServices.jpg";
import makeup from "../images/Makeup.jpeg";
import manicure from "../images/manicure.jpeg";
import pedicure from "../images/pedicure.jpg";
import nails from "../images/NailServices.jpeg";
import S1 from "../images/DesktopImages/S1.jpeg";
import S2 from "../images/DesktopImages/S2.jpeg";
import S3 from "../images/DesktopImages/S3.jpeg";
import S4 from "../images/DesktopImages/S4.jpeg";
import S5 from "../images/DesktopImages/S5.jpeg";
import S6 from "../images/DesktopImages/S6.jpeg";
import S7 from "../images/DesktopImages/S7.jpeg";

import M1 from "../images/MobileImages/M1.jpg";
import M2 from "../images/MobileImages/M2.jpg";
import M3 from "../images/MobileImages/M3.jpg";
import M4 from "../images/MobileImages/M4.jpg";
import M5 from "../images/MobileImages/M5.jpg";
import M6 from "../images/MobileImages/M6.jpg";

import hairTreatment_service from "../images/HairTreatment_service.jpeg";
import hairSpa_service from "../images/HairSpa_service.jpeg";
import hairServices from "../images/HairServices.jpeg";
import beauty_service from "../images/BeautyServices.jpg";

import { ReactComponent as HairIcon } from "../images/hair-style.svg";
import { ReactComponent as HairCut } from "../images/hair-cut.svg";
import { ReactComponent as manicureIcon } from "../images/MANICURE-icon.svg";
import { ReactComponent as nailIcon } from "../images/NAILS-icon.svg";
import { ReactComponent as makeupIcon } from "../images/FACIALS-icon.svg";

export const PostDetailsUrl =
  "https://script.google.com/macros/s/AKfycbwMSPa8M4nAcsJiYEI_TguIDAcvGZfMulBH01E-1FEBZo9i47ziV8p5R69uqUM_2_j7/exec";
export const staticData = {
  buttonText: {
    bookAppointment: "Book Appointment",
  },
  home: {
    heading: "",
    tagLine: "Exquisite Beauty, Tailored for You",
    description:
      "As you Join us at Sree Moulika Salon, where luxury meets personalization, and beauty takes on a whole new meaning. We believe that beauty is an expression of individuality, and every client has a unique story waiting to be unveiled. With this principle at our core, our talented team of professionals is dedicated to tailoring our services to enhance your inherent beauty and embrace your distinct essence.",
    subDescription:
      "Let's create a beauty story together, one that will be cherished for a lifetime. Welcome to our exquisite world of beauty and care",
    ourServices: "Our Services",
  },
};

export const homeImagesList = [
  {
    img: S1,
    title: "Hair Treatment",
  },
  {
    img: S2,
    title: "Hair Services",
  },
  {
    img: S3,
    title: "Hair Spa",
  },
  {
    img: S4,
    title: "Glamour Services",
  },
  {
    img: S5,
    title: "Nails Services",
  },
  {
    img: S6,
    title: "Makeup",
  },
  {
    img: S7,
    title: "Manicure",
  },
  {
    img: pedicure,
    title: "Pedicure",
  },
];


export const mobileImagesList = [
  {
    img: M1,
    title: "Glamour Services",
  },
  {
    img: M2,
    title: "Makeup",
  },
  {
    img: M3,
    title: "Makeup",
  },
  {
    img: M4,
    title: "Makeup",
  },
  {
    img: M5,
    title: "Makeup",
  },
  {
    img: M6,
    title: "Makeup",
  },
];
export const ImageServiceList = [
  {
    img: hairTreatment_service,
    title: "Hair Treatment",
  },
  {
    img: hairServices,
    title: "Hair Services",
  },
  {
    img: hairSpa_service,
    title: "Hair Spa",
  },
  {
    img: beauty_service,
    title: "Beauty Services",
  },
  {
    img: nails,
    title: "Nails Services",
  },
  {
    img: makeup,
    title: "Makeup",
  },
  {
    img: manicure,
    title: "Manicure",
  },
  {
    img: pedicure,
    title: "Pedicure",
  },
];

export const NailsService = [
  {
    name: "Polish",
    list: ["Nail extensions+ regular polish", "regular polish"],
  },
  {
    name: "BUILDER/HARD GEL",
    list: ["hard gel overlay + gel polish", "hard gel ecapsulated nails"],
  },
  {
    name: "ACRYLICS",
    list: [
      "acrylic overlay + gel polish",
      "acrylic encapsulation",
      "pink and white/french nails",
      "ombre",
      "acrylic filling",
    ],
  },
  {
    name: "GEL POLISH",
    list: [
      "anail extensions + gel polish",
      "gel polish",
      "gel french nails",
      "gel ombre ",
      "gel removal",
      "gel filling",
    ],
  },
  {
    name: "DIP POWDER",
    list: ["dip + extensions", "dip powder color", "pink and white", "ombre"],
  },
  {
    name: "NAIL ART",
    list: [
      "animal prints",
      "chrome/holographic nails",
      "cateye",
      "marble nails",
      "3d flowers",
      "rhinestones",
      "swaroski crystals",
      "stamping",
    ],
  },
  {
    name: "NAIL REPAIR",
    list: ["silk wrap"],
  },
];

export const ServicesList = [
  {
    img: HairIcon,
    title: "Hair Services",
    list: [
      "women/Men Hair cut",
      "blow drying",
      "straightning, curling , crimping",
    ],
  },
  {
    img: HairCut,
    title: "Hair Spa",
    list: [
      "Deep Hydration Hair Spa",
      "Scalp Detox Hair Spa",
      "Repair & Strengthen Hair Spa",
      "Anti-Frizz Smoothing Hair Spa",
      "Color Protection Hair Spa",
      "Volumizing Hair Spa",
      "Anti-Dandruff Scalp Treatment",
    ],
  },
  {
    img: HairIcon,
    title: "Hair Treatment",
    list: [
      "Hair Smoothening",
      "Keratin Treatment",
      "Hair Botox Therapy",
      "Hair Repair & Strengthening",
    ],
  },
  {
    img: makeupIcon,
    title: "Glamour services",
    list: ["Eyebrow", "Upper lip"],
  },
  {
    img: makeupIcon,
    title: "Makeup",
    list: [
      "Lash Extensions",
      "Hair Extensions",
      "Bridal Makeover",
      "Air Brush Makeup",
      "Party Makeup",
      "Kids Makeup",
    ],
  },
  {
    img: manicureIcon,
    title: "Manicure",
    list: [
      "Classic Manicure",
      "Gel Manicure",
      "Luxury Spa Manicure",
      "Collagen Manicure",
      "French Manicure",
      "Nail Art Manicure",
      "Hydrating Paraffin Manicure",
      "Natural Nail Strengthener Manicure",
    ],
  },
  {
    img: manicureIcon,
    title: "Pedicure",
    list: [
      "Classic pedicure",
      "Gel pedicure",
      "Luxury Spa pedicure",
      "Collagen  pedicure",
      "French pedicure",
      "Nail Art pedicure",
      "Hydrating Paraffin pedicure",
      "Natural Nail Strengthener pedicure",
      "Glimmering Pedicure Delight",
      "Detoxifying pedicure",
      "Signature Pedicure (lavender,mango,mint)",
    ],
  },
  {
    img: nailIcon,
    title: "Nails",
    list: [],
    nailsEnhancement: NailsService,
  },
];
